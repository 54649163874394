import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import SectionsContainer from '../../components/SectionsContainer'

const RewardFaqs = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulFaq(
        filter: { type: { regex: "/.*Reward.*/" } }
        sort: { fields: [type, order] }
      ) {
        edges {
          node {
            id
            title
            type
            body {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }
  `)

  const tripOneFaqs = data.allContentfulFaq.edges.filter((faq) => faq.node.type === 'Rewards')
  const tripTwoFaqs = data.allContentfulFaq.edges.filter((faq) => faq.node.type === 'Reward Trips')

  return (
    <SectionsContainer>
      <section className="wrap">
        <h2 style={{ textTransform: 'uppercase', fontWeight: '600' }} className="headline--medium center-align">FAQ</h2>
        <h3 className="headline--small">Rewards Program</h3>
        {tripOneFaqs.map(({ node: QAndA }) => (
          <div key={QAndA.id}>
            <h4 className="section-label">{QAndA.title}</h4>
            <div
              className="body-content"
              style={{ marginBottom: '20px' }}
              dangerouslySetInnerHTML={{
                __html: QAndA.body.childMarkdownRemark.html,
              }}
            />
          </div>
        ))}
        <h3 className="headline--small">Paradise + JetSet</h3>
        {tripTwoFaqs.map(({ node: QAndA }) => (
          <div key={QAndA.id}>
            <h4 className="section-label">{QAndA.title}</h4>
            <div
              className="body-content"
              style={{ marginBottom: '20px' }}
              dangerouslySetInnerHTML={{
                __html: QAndA.body.childMarkdownRemark.html,
              }}
            />
          </div>
        ))}
      </section>
    </SectionsContainer>
  )
}

export default RewardFaqs
